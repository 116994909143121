<template>
  <div data-test="course-overview-page">
    <SectionWrap
      id="page-title"
      :aria-label="
        $t('page.course.overview.title', { type: course.category_title })
          | capitalize
      "
      element="section"
      bg-color="alt-darker"
      class="course-section course-section--title"
    >
      <div class="course-section__main course-section__main-with-aside">
        <h2 id="page-subheader">
          {{
            $t('page.course.overview.title', { type: course.category_title })
              | capitalize
          }}
        </h2>
      </div>
      <div
        v-if="isMajor"
        aria-label="At a glance"
        class="course-section__aside"
      >
        <div ref="atAGlance" class="at-a-glance-wrap">
          <AtAGlance
            :cta-buttons-arr="ctaButtonsArr"
            :offering-type="course.offering_type"
            :offering-level="offeringLevel"
            :related-courses="relatedCourses"
            :offering-category="course.category"
            :course="course"
          />
        </div>
      </div>
    </SectionWrap>

    <div data-test="course-overview-page">
      <SectionWrap
        id="course-overview"
        ref="courseOverview"
        :class="{ 'course-overview--none': !course.overview }"
        :aria-label="
          $t('page.course.overview.title', { type: '' }) | capitalize
        "
        element="section"
        bg-color="white"
        class="course-section"
      >
        <div
          class="course-section__main"
          :class="{ 'course-section__main-with-aside': isMajor }"
        >
          <HeaderIcon>
            {{ $t('page.course.overview.title', { type: '' }) | capitalize }}
          </HeaderIcon>
          <div v-if="honoursAvailable && honoursUrl" class="shim-pt1 shim-pb1">
            <a :href="honoursUrl">Honours is available for this course</a>
          </div>
          <!-- eslint-disable vue/no-v-html -->
          <div
            ref="content"
            class="course-content shim-pr0"
            data-test="course-overview-content"
            v-html="course.overview.content"
          />
          <!-- eslint-enable vue/no-v-html -->
        </div>
      </SectionWrap>

      <RelatedStudyAreas class="related-study-areas" :course="course" />

      <CtaButtons :next="nextCta" />
      <!-- MSPACE Liveagent chat -->
      <div v-if="isMspaceCourse" id="liveagent" class="liveagent" />
    </div>
  </div>
</template>

<script>
import { SectionWrap } from '@unimelb/pattern-lib-vue';
import { get } from '@/helpers/get';
import { structureTitle } from '~/helpers/navigationTitles';
import AtAGlance from '~/components/AtAGlance.vue';
import HeaderIcon from '~/components/HeaderIcon.vue';
import CtaButtons from '~/components/CtaButtons.vue';
import { wrapIframeEmbeds } from '~/helpers/client';
import RelatedStudyAreas from '~/components/RelatedStudyAreas.vue';

export default {
  name: 'Overview',
  components: {
    AtAGlance,
    SectionWrap,
    HeaderIcon,
    CtaButtons,
    RelatedStudyAreas,
  },
  props: {
    course: {
      type: Object,
      default: () => {},
    },
    residency: {
      type: String,
      default: 'domestic',
    },
  },
  data() {
    return {
      basePath: process.env.basePath,
    };
  },
  head() {
    return {
      title: `${this.course.title} ${this.$t('app.meta.title.suffix')}`,
    };
  },
  computed: {
    structureLabel() {
      return structureTitle(this.course.offering_type);
    },
    honoursAvailable() {
      return get(
        this.course,
        ['dotpoints', this.residency],
        ['honours_availability'],
        false,
      );
    },
    honoursUrl() {
      return get(
        this.course,
        ['dotpoints', this.residency, 'honours_url'],
        null,
      );
    },
    nextCta() {
      if (
        ['major', 'specialisation', 'minor'].includes(this.course.offering_type)
      ) {
        return {
          title: this.structureLabel,
          href: `${this.basePath}/courses/${this.$route.params.component}/${this.course.slug}/structure/#nav`,
        };
      }
      return {
        title: this.$t('page.course.entry-requirements.title'),
        href: `${this.basePath}/courses/${this.$route.params.component}/${this.course.slug}/entry-requirements/#nav`,
      };
    },
    offeringLevel() {
      if (this.course.category === 'honours') {
        return 'graduate';
      }

      return this.course.offering_level;
    },
    relatedCourses() {
      return get(this.course, ['related_courses'], false);
    },
    isMspaceCourse() {
      return this.course.metadata.mspace;
    },
    dotpoints() {
      return get(this.course, ['dotpoints', this.residency], false);
    },
    ctaButtonsArr() {
      const { actions } = this.course.at_a_glance;

      const connectItemIndexPosition = 1;
      actions[connectItemIndexPosition] = this.connectCta(
        actions,
        connectItemIndexPosition,
      );

      if (this.course.offering_type === 'course') {
        // filter items that contains link
        const filteredActions = actions
          .filter((action) => action.link !== '')
          .map((action) => {
            const item =
              action.text === 'Find out how to apply'
                ? this.updatedHowToApplyCta()
                : {
                    ...action,
                    class: 'btn--secondary',
                  };

            return item;
          });

        return filteredActions;
      }

      const defaultActions = new Array(actions[connectItemIndexPosition]);
      // filter items that contains link
      const filteredDefaultActions = defaultActions.filter(
        (item) => item.link !== '',
      );

      return filteredDefaultActions;
    },
    isMajor() {
      const offeringType = ['major', 'minor', 'specialisation'];
      return offeringType.includes(this.course.offering_type);
    },
  },
  mounted() {
    if (this.$refs.content) {
      wrapIframeEmbeds(this.$refs.content);
    }
  },
  methods: {
    connectCta(actions, connectItemIndexPosition) {
      const MinLength = 5;
      const connectItem = actions[connectItemIndexPosition];

      connectItem.text =
        connectItem.text.length > MinLength
          ? connectItem.text
          : 'Register for updates';

      connectItem.link =
        connectItem.link.length > MinLength ? connectItem.link : '';

      return connectItem;
    },
    updatedHowToApplyCta() {
      return {
        text: this.$t('page.course.overview.cta[0].title'),
        link: this.$t('page.course.overview.cta[0].href', {
          basePath: process.env.basePath,
          courseType: this.course.offering_level,
          slug: this.course.slug,
        }),
      };
    },
  },

  meta: {
    breadcrumb: 'Overview',
  },
};
</script>

<style lang="postcss">
.related-study-areas {
  padding-top: 1rem;
}
</style>
